import React from 'react'

function StudentsCountContent({ f, m, t }) {
    return (
        <div className='row'>
            <div className="col col-auto me-0 pe-0" style={{ whiteSpace: "nowrap" }}>
                {f}
            </div>
            <div className="col col-auto me-0 pe-0" style={{ whiteSpace: "nowrap" }}>
                {m}
            </div>
            <div className="col col-auto me-0 pe-0" style={{ whiteSpace: "nowrap" }}>
                {t}
            </div>
        </div>
    )
}

export default StudentsCountContent
