import React from 'react'
import { useAuth } from '../../context/AuthContext'

function LevelSplit({ primary, ordinary }) {
    const auth = useAuth()

    if (auth.user.level === "PRIMARY") {
        return <>{primary}</>
    }
    else {
        return <>{ordinary}</>
    }
}

export default LevelSplit
