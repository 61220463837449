import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import { saveAs } from 'file-saver'

function NewSubjectTopicCoverage() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")
    const [errors, setErrors] = useState([])

    const [forms, setForms] = useState([])
    const [subjects, setSubjects] = useState([])

    const [selectedForm, setSelectedForm] = useState("")
    const [selectedSubject, setSelectedSubject] = useState("")
    const [file, setFile] = useState("")

    const fetchForms = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes/bare`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            setMessage('Failed to fetch forms. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            setMessage('Failed to fetch subjects. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const downloadTemplate = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/${selectedForm}/${selectedSubject}/template`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }

            const blob = await response.blob()
            const filename = `MSSIS-Subject Topics Template.xlsx`
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to download subject topics template. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const uploadTemplate = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('Please wait while data is being processed.')
        setVariant('info')
        setErrors([])

        const formData = new FormData()
        formData.append('file', file)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
                if (data.errors) {
                    setErrors(data.errors)
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('An error occurred while uploading file. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Register Subject Topics</p>
            <div className='shadow p-2 mb-3'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <div className="mb-3">
                    <p>
                        Select a class/form and a subject, download it's template, fill the template according
                        the format provided and upload it here.
                    </p>

                    <form onSubmit={downloadTemplate} className="mb-3">
                        <div className="row">
                            <div className="col-12 col-lg-3 mb-2">
                                <label htmlFor='form' className='form-label'>Form</label>
                                <select
                                    id='form'
                                    className='form-select rounded-0'
                                    required
                                    value={selectedForm}
                                    onChange={e => setSelectedForm(e.target.value)}
                                >
                                    <option>Select Form</option>
                                    {
                                        forms.map(form =>
                                            <option key={form.id} value={form.id}>{form.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-12 col-lg-3 mb-2">
                                <label htmlFor='subject' className='form-label'>Subject</label>
                                <select
                                    id='subject'
                                    className='form-select rounded-0'
                                    required
                                    value={selectedSubject}
                                    onChange={e => setSelectedSubject(e.target.value)}
                                >
                                    <option>Select Subject</option>
                                    {
                                        subjects.map(subject =>
                                            <option key={subject.id} value={subject.id}>{subject.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                                <button type='submit' className='btn btn-primary rounded-0 mt-4' disabled={isLoading}>
                                    <span className="me-2">Download</span>
                                    <i className='bi bi-download'></i>
                                </button>
                            </div>
                        </div>
                    </form>

                    <p>
                        After downloading, remove the example topics and start filling your own.
                        Be sure to follow the example format when filling data.
                    </p>

                    <div className="my-2">
                        {
                            errors && errors.map((error, index) => (
                                <div key={index} className='alert alert-danger p-2 my-1'>
                                    {error}
                                </div>
                            ))
                        }
                    </div>

                    <form encType='multipart/form-data' onSubmit={uploadTemplate} className='text-end mb-3'>
                        <input
                            type='file'
                            className='form-control mb-3 rounded-0'
                            accept='.xlsx, .xls'
                            required
                            disabled={isLoading}
                            onChange={e => setFile(e.target.files[0])}
                        />
                        <button className='btn btn-primary rounded-0 px-4' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Uploading... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Upload
                                        </span>
                                        <i className='bi bi-upload'></i>
                                    </>
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewSubjectTopicCoverage
