import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'

function NewGrades() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [grades, setGrades] = useState([
        {
            grade: '',
            score: '',
            points: '',
            remark: '',
            indicator: 'success'
        }
    ])

    const validateGrades = () => {
        if (grades.length < 3) {
            setMessage('Please add at least three grades.')
            setVariant('warning')
            return false
        }

        for (let i = 0; i < grades.length; i++) {
            // check that no two grades have the same minimum score
            for (let j = i + 1; j < grades.length; j++) {
                if (grades[i].score === grades[j].score) {
                    setMessage('Two grades cannot have the same minimum score.')
                    setVariant('warning')
                    return false
                }
            }

            // check that the minimum score is a number
            if (isNaN(grades[i].score)) {
                setMessage('Minimum score must be a number.')
                setVariant('warning')
                return false
            }

            // check that the minimum score is not negative or greater than 100
            if (grades[i].score < 0 || grades[i].score > 100) {
                setMessage('Minimum score cannot be negative or greater than 100.')
                setVariant('warning')
                return false
            }

            return true
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        if (!validateGrades()) {
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/grades`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    grades
                })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage("Failed to save grades. Please try again.")
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>New Grading System</p>
            <p className='text-muted'>This will replace the existing one in all upcoming exams that will not have their own specific grades set.</p>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <form onSubmit={handleSubmit}>
                {
                    grades.map((grade, index) => (
                        <div className='row mx-0 mb-3 border p-1' key={index}>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='grade' className='form-label'>Grade</label>
                                    <input
                                        type='text'
                                        id='grade'
                                        className='form-control rounded-0'
                                        placeholder='A'
                                        required
                                        value={grade.grade}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].grade = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='score' className='form-label'>Minimum Score</label>
                                    <input
                                        type='text'
                                        id='score'
                                        className='form-control rounded-0'
                                        placeholder='80'
                                        required
                                        value={grade.score}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].score = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='points' className='form-label'>Points</label>
                                    <input
                                        type='text'
                                        id='points'
                                        className='form-control rounded-0'
                                        placeholder='1'
                                        required
                                        value={grade.points}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].points = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='remark' className='form-label'>Remark</label>
                                    <input
                                        type='text'
                                        id='remark'
                                        className='form-control rounded-0'
                                        placeholder='Excellent'
                                        required
                                        value={grade.remark}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].remark = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='indicator' className='form-label'>
                                        <span className='me-2'>Indicator</span>
                                        <span className={`badge bg-${grade.indicator} rounded-0`}>{grade.remark}</span>
                                    </label>
                                    <select
                                        className='form-select rounded-0'
                                        id='indicator'
                                        required
                                        value={grade.indicator}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].indicator = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    >
                                        <option value='success'>Green</option>
                                        <option value='primary'>Blue</option>
                                        <option value='info'>Light Blue</option>
                                        <option value='warning'>Yellow</option>
                                        <option value='danger'>Red</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    ))
                }

                <div>
                    {
                        message &&
                        <div className={`alert alert-${variant} p-2`} role="alert">
                            {message}
                        </div>
                    }
                </div>

                <div className="d-flex align-items-center justify-content-end mb-3">
                    <button
                        type='button'
                        className='btn btn-secondary px-4 rounded-0 me-2'
                        onClick={() => setGrades([
                            ...grades, {
                                grade: '',
                                score: '',
                                remark: '',
                                indicator: 'success'
                            }
                        ])}
                    >
                        <span className='me-2'>Add Grade</span>
                        <i className='bi bi-plus-circle'></i>
                    </button>
                    <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                        {
                            isLoading ?
                                <>Saving... <Loading /></> :
                                <>
                                    <span className="me-2">
                                        Save
                                    </span>
                                    <i className='bi bi-floppy2-fill'></i>
                                </>
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default NewGrades
