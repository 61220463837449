import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import RoleOffice from '../../components/offices/RoleOffice'

function InternalResults() {
    const auth = useAuth()
    const params = useParams()
    const exam_id = params.exam_id
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [exam, setExam] = useState(location.state.exam)

    const fetchExam = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/${exam_id}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setExam(data.exam)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const sendSMS = async (examId, formId) => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/sms/report/${examId}/${formId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({
                    type: "all"
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setMessage(data.message)
                setVariant("success")
            }
        }
        catch (error) {
            setMessage("Failed to send SMS. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExam()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>{exam.name}</p>
            <div className="body shadow p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Exam Details</p>
                <div className=''>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam.end_date}</span>
                    </p>
                </div>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading ?
                    (
                        <div className='text-center my-2'>
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <>
                            <div className="body shadow p-2 mb-3">
                                <p className='h4 fw-bold text-muted'>Select to View Class Results</p>
                                {
                                    exam.forms && exam.forms.map((form, index) => (
                                        <div className='mb-2' key={index}>
                                            <span className='mb-2'>{form.name}</span>
                                            <div className="row mx-0">
                                                <div className="col-12 col-lg-auto px-3 me-2 mb-2">
                                                    <Link
                                                        to={`${form.id}`}
                                                        state={{ exam: exam, form: form }}
                                                        className='fw-bold'>
                                                        Overall Results
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-lg-auto px-3 me-2 mb-2">
                                                    <Link
                                                        to={`${form.id}/overall-ten`}
                                                        state={{ exam: exam, form: form }}
                                                        className='fw-bold'>
                                                        Overall Ten Students
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-lg-auto px-3 me-2 mb-2">
                                                    <Link
                                                        to={`${form.id}/subjectwise`}
                                                        state={{ exam: exam, form: form }}
                                                        className='fw-bold'>
                                                        Overall Ten Students Subject Wise
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-lg-auto px-3 me-2 mb-2">
                                                    <Link
                                                        to={`${form.id}/reports`}
                                                        state={{ exam: exam, form: form.name }}
                                                        className='fw-bold'>
                                                        Exam Report Forms
                                                    </Link>
                                                </div>
                                                <RoleOffice roles={["Headmaster", "Academic Master"]} element={
                                                    <div className="dropdown col-12 col-lg-auto px-3 me-2 mb-2">
                                                        <button className="btn btn-sm btn-primary rounded-0 dropdown-toggle px-3" type="button" id="dropdownMenuButtonSendSMS" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span className="me-2">Send SMS to Parents</span>
                                                        </button>
                                                        <ul className="dropdown-menu rounded-0 px-0 mx-0" aria-labelledby="dropdownMenuButtonSendSMS">
                                                            <li>
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() => sendSMS(exam.id, form.id)}
                                                                >
                                                                    All {form.name} Students
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to={`${form.id}/sms`}
                                                                    state={{ exam: exam, form: form.name }}
                                                                    className="dropdown-item">
                                                                    Select {form.name} Students
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                } />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
            }

        </div>
    )
}

export default InternalResults
