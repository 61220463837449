import React from 'react'
import './Loading.css'

function Loading() {
  return (
    <div className='fs-5 spin'>
        <i className='bi bi-arrow-clockwise spin'></i>
    </div>
  )
}

export default Loading
