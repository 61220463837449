import React from 'react'

function StudentsCountHeader({ name }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className='text-center'>
            <span className='mb-2'>{name}</span>
            <div className='row'>
                <div className="col">F</div>
                <div className="col">M</div>
                <div className="col">T</div>
            </div>
        </div>
    )
}

export default StudentsCountHeader
