import React, { useState } from 'react'
import logo from '../../assets/mssis.png'
import { BASE_API_URL } from '../../utils/constants'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../../components/loading/Loading'

function PasswordReset() {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [username, setUsername] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        try {
            const resp = await fetch(`${BASE_API_URL}/profile/password/reset?username=${username}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const data = await resp.json()
            if (!resp.ok) {
                setMessage(data.message)
                setVariant('danger')
            }
            else {
                setMessage(data.message)
                setVariant('success')
                navigate('/password-reset-new')
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
        setIsLoading(false)
    }

    return (
        <div className="container">
            <div className="row d-flex align-items-center justify-content-center vh-100">
                <div className="col-12 col-lg-6 text-center mb-3">
                    <img src={logo} alt="MSSIS Logo" style={{ width: "120px" }} className="img-fluid" />
                    <br />
                    <h1 className="text-muted h3">
                        Modern Streamlined Schools' Information System (MSSIS)
                    </h1>
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <h2 className="text-muted h4">Password reset</h2>
                    <small className="mb-3">Enter your username, a code will be sent to your email or SMS.</small>
                    <div className="my-2 text-center">
                        {
                            message && <span className={`text-${variant}`}>{message}</span>
                        }
                    </div>
                    <form className="mx-2" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label fw-bold">Username</label>
                            <input
                                type="text"
                                name="username"
                                id="username"
                                className="form-control rounded-0"
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-2 text-end text-primary">
                            <Link to={"/password-reset-new"}>Next Page</Link>
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary w-100 rounded-0 fw-bold">
                                {
                                    isLoading ?
                                        <>
                                            Submitting... <Loading />
                                        </> :
                                        <>
                                            Submit
                                        </>
                                }
                            </button>
                        </div>
                        <Link to={"/login"} className="text-primary">Back to login</Link>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PasswordReset
