import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { saveAs } from 'file-saver'
import RoleOffice from '../../components/offices/RoleOffice'

function StudentDiscipline() {
    const auth = useAuth()
    const params = useParams()
    const location = useLocation()
    const exam_id = params.exam_id
    const form = location.state.form
    const exam = location.state.exam

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [errors, setErrors] = useState([])

    const [disciplines, setDisciplines] = useState([])
    const [candidates, setCandidates] = useState([])
    const [file, setFile] = useState(null)

    const fetchDisciplines = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/discipline/${exam_id}/${form}`
            if (exam.type == "Internal") {
                url = `${url}?school=${exam.participants[0]["id"]}`
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setErrors(data.errors)
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setDisciplines(data.disciplines)
                setCandidates(data.student_disciplines)
            }
        }
        catch (error) {
            setMessage('Failed to fetch candidates. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const downloadTemplate = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/discipline/${exam_id}/${form}/template`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }
            const filename = `${suff}-${form}-Discipline Scores.xlsx`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to download Template. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('Please wait while data is being processed.')
        setVariant('info')
        setErrors([])

        const formData = new FormData()
        formData.append('file', file)

        try {
            const response = await fetch(`${BASE_API_URL}/discipline/${exam_id}/${form}`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
                setErrors(data.errors)
            }
            else {
                setMessage(data.message)
                setVariant('success')
                fetchDisciplines()
            }
        }
        catch (error) {
            setMessage('An error occurred while uploading file. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchDisciplines()
    }, [])

    return (
        <div className='pt-2'>
            <p className='d-flex flex-wrap align-items-center justify-content-between mb-3'>
                <span className="h3 fw-bold text-muted">{form} Discipline Scores</span>
                <RoleOffice roles={[form]} element={
                    <button
                        className='btn btn-primary btn-sm rounded-0 fw-bold me-2'
                        onClick={downloadTemplate}
                    >
                        <span className="me-2">Download Template</span>
                        <i className='bi bi-download'></i>
                    </button>
                } />
            </p>

            <RoleOffice roles={[form]} element={
                <div className="body shadow p-2 mb-4">
                    <p>Download template, fill it with candidate discipline scores then upload it here.</p>
                    <div>
                        {
                            message &&
                            <>
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                                <div className="my-2">
                                    {
                                        errors && errors.map((error, index) => (
                                            <div key={index} className='alert alert-danger p-2 my-1'>
                                                {error}
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <form encType='multipart/form-data' onSubmit={handleUpload}>
                        <input
                            type='file'
                            className='form-control mb-3 rounded-0'
                            accept='.xlsx, .xls'
                            required
                            disabled={isLoading}
                            onChange={e => setFile(e.target.files[0])}
                        />
                        <div className="text-end">
                            <button className='btn btn-primary rounded-0 px-4' disabled={isLoading}>
                                {
                                    isLoading ?
                                        <>Uploading... <Loading /></> :
                                        <>
                                            <span className="me-2">
                                                Upload
                                            </span>
                                            <i className='bi bi-upload'></i>
                                        </>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            } />

            <div className="body shadow p-2 mb-4">
                {
                    isLoading ?
                        <div className='text-center'>Loading... <Loading /></div> :
                        <>
                            <p className='h4 fw-bold text-muted'>Uploaded Scores</p>
                            {
                                candidates && candidates.length < 1 &&
                                <p className='text-center'>No student discipline scores uploaded.</p>
                            }

                            {
                                candidates && candidates.length > 0 &&
                                <div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Index Number</th>
                                                    {
                                                        disciplines && disciplines.map((dis, index) => (
                                                            <th key={index}>{dis}</th>
                                                        ))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    candidates && candidates.map((cand, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{cand.index_number}</td>
                                                            {
                                                                disciplines && disciplines.map((dis, ind) => (
                                                                    <td>{cand["scores"][dis]}</td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default StudentDiscipline
