import React from 'react'

function SingleSubjectTopicCoverage({ coverage, isLogBook }) {
    return (
        <div className="table-responsive">
            <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                <thead className='align-middle'>
                    <tr>
                        <th className='vertical-text'>MONTH</th>
                        <th className='vertical-text'>WEEK</th>
                        <th>MAIN TOPIC</th>
                        <th>SUB-TOPIC</th>
                        <th className='nowrap'>CONCEPTS COVERED</th>
                        <th>DATE STARTED</th>
                        <th>DATE ENDED</th>
                        <th className='vertical-text'>NO. OF <br/> PERIODS TAUGHT</th>
                        { !isLogBook && <th className='vertical-text'>TOPIC <br/> COVERGAGE %</th> }
                        <th>SUBJECT TEACHER'S REMARKS & SIGNATURE</th>
                        <th>HEAD OF DEPARTMENT REMARKS & SIGNATURE</th>
                        { isLogBook && <th>HEAD OF SCHOOL REMARKS & SIGNATURE</th> }
                    </tr>
                </thead>
                <tbody>
                    {
                        coverage?.map((row, rowIndex) =>
                            <React.Fragment key={rowIndex}>
                                {
                                    row?.sub_topics?.map((subTopic, subIndex) =>
                                        <tr key={subIndex}>
                                            {
                                                subIndex === 0 &&
                                                <>
                                                    <td rowSpan={row.sub_topics.length} className='vertical-text'>{row.month}</td>
                                                    <td rowSpan={row.sub_topics.length}>{row.week}</td>
                                                    <td rowSpan={row.sub_topics.length} className='text-start'>{row.topic}</td>
                                                </>
                                            }
                                            <td className='text-start'>{subTopic.name}</td>
                                            <td className='text-start px-0'>
                                                <ul className='mx-0 px-0' style={{listStyleType: "none"}}>
                                                    {subTopic.concepts_covered?.map((concept, idx) =>
                                                        <li key={idx} className='mx-0 px-2 d-flex'>
                                                            <i className="bi bi-dot me-1 fw-bold"></i>
                                                            <span>{concept}</span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </td>
                                            <td>{subTopic.date_started}</td>
                                            <td>{subTopic.date_ended}</td>
                                            <td>{subTopic.periods_taught}</td>
                                            {
                                                subIndex === 0 &&
                                                <>
                                                    { !isLogBook && <td rowSpan={row.sub_topics.length}>{row.coverage}</td> }
                                                    <td rowSpan={row.sub_topics.length}>{row.teacher_remarks}</td>
                                                    <td rowSpan={row.sub_topics.length}>{row.hod_remarks}</td>
                                                    { isLogBook && <td rowSpan={row.sub_topics.length}>{row.hm_remarks}</td> }
                                                </>
                                            }
                                        </tr>
                                    )}
                            </React.Fragment>
                        )}
                </tbody>
            </table>
        </div>
    )
}

export default SingleSubjectTopicCoverage
