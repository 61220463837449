import React from 'react'
import { Link } from 'react-router-dom'

function GeneralSettings() {
	return (
		<div className="py-2">
			<Link to={`/settings/password`} className='btn btn-primary btn-sm fw-bold rounded-0'>
				<span className="me-2">Update Password</span>
				<i className='bi bi-lock'></i>
			</Link>
		</div>
	)
}

export default GeneralSettings
