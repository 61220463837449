// LoginCountChart.js
import React from 'react'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement, // Import PointElement
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js'

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, Filler)

const LoginCountChart = ({ data }) => {
    // Prepare the data for the chart
    const chartData = {
        labels: data.map(item => item.date), // Dates as labels
        datasets: [
            {
                label: 'Login Count',
                data: data.map(item => item.count), // Counts as data
                fill: true, // Fill the area under the line
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                tension: 0.2, // Smoothness of the line
                pointRadius: 5, // Size of the points
                pointHoverRadius: 7, // Size of the points on hover
            },
        ],
    }

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    }

    return (
        <div className='col col-lg-7 mx-auto'>
            <Line data={chartData} options={options} />
        </div>
    )
}

export default LoginCountChart
