import React from 'react';
import './ConfirmationDialog.css';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message, isLoading=false, confirmColor="danger" }) => {
    if (!open) return null;

    return (
        <div className="dialog-overlay">
            <div className="dialog-container">
                <h2 className="dialog-title">{title}</h2>
                <p className="dialog-message">{message}</p>
                {
                    isLoading &&
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                <div className="dialog-actions">
                    <button className="btn btn-secondary rounded-0" onClick={onClose}>Cancel</button>
                    <button className={`btn btn-${confirmColor} rounded-0`} onClick={onConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;
