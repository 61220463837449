import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import { useAuth } from '../../context/AuthContext'

function SingleFacility() {
    const auth = useAuth()
    const params = useParams()
    const name = params.name

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [facilities, setFacilities] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true
        },
        {
            name: 'Count',
            selector: row => row.count,
            sortable: true
        }
    ]

    const fetchFacilities = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/infrastructure/${name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setFacilities(data.details)
            }
        }
        catch (error) {
            setMessage("Failed to fetch facilities details")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFacilities()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                Facilities: {name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </p>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="shadow my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={facilities}
                            highlightOnHover
                        />
                }
            </div>
        </div>
    )
}

export default SingleFacility
