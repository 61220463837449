import { createContext, useState, useContext } from "react"
import { jwtDecode } from 'jwt-decode'
import { BASE_API_URL } from "../utils/constants";

export const AuthContext = createContext(null)

export const AuthProvider = ({ children }) =>{
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [token, setToken] = useState(localStorage.getItem("token"))
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refresh_token"))

  const login = (token_string, refresh_token) =>{
    const user = jwtDecode(token_string)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', token_string)
    localStorage.setItem('refresh_token', refresh_token)
    setUser(user)
    setToken(token_string)
    setRefreshToken(refresh_token)
  }

  const logout = async () =>{
    try{
      const response = await fetch(`${BASE_API_URL}/logout`, {
        headers: {
          'x-access-token': token
        }
      })

      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      setUser(null)
      setToken(null)
    }
    catch(error){
      // nothing to do for now
    }
  }

  const refresh = async () => {
    const resp = await fetch(`${BASE_API_URL}/refresh`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "refresh_token": refreshToken
        })
    })              

    if (!resp.ok) {
      return false
    }
    else {
        const d = await resp.json()
        localStorage.setItem('token', d.token)
        const user = jwtDecode(d.token)
        localStorage.setItem('user', JSON.stringify(user))
        setToken(d.token)
        setUser(user)
        return true
    }
  }

  const isLoggedIn = () =>{
    try{
      const loggedinUser = JSON.parse(localStorage.getItem('user'))
      if (loggedinUser === null){
        return false
      }

      const isExpired = loggedinUser.exp * 1000 < Date.now()
      if (isExpired){
        return false
      }
      return true
    }
    catch(err){
      return false
    }
  }
  
  const updateProfile = (profile) =>{
    setUser({...user, profile: profile})
  }

  const contextValue = {
    user,
    token,
    refreshToken,
    setToken,
    setRefreshToken,
    login,
    logout,
    refresh,
    isLoggedIn,
    updateProfile
  }

  return(
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () =>{
  const contextValue = useContext(AuthContext)
  return contextValue
}
