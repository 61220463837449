// Utility function to convert date from dd/mm/yyyy to yyyy-mm-dd
const convertDateFormat = (date) => {
    if (!date) return date;

    // check if date isnt in the format and return date as it is
    if (!date.includes('/')) return date;

    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
};

export { convertDateFormat };
