import React, { useState } from 'react'
import './table-styles.css'

function TopNStudents({ title, headers, students }) {
    const [rowSpan, setRowSpan] = useState(2)

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                    <thead>
                        {
                            title &&
                            <tr className='align-middle'>
                                <th colSpan={headers.length + 1}>{title.toUpperCase()}</th>
                            </tr>
                        }
                        <tr className='align-middle'>
                            <th rowSpan={rowSpan}>S/N</th>
                            {
                                headers.map((header, index) => {
                                    if (header.span) {
                                        return <th colSpan={header.children.length} key={index} className={`${header.vertical}`}>{header.name.toUpperCase()}</th>
                                    }
                                    else {
                                        return <th rowSpan={rowSpan} key={index}>{header.name.toUpperCase()}</th>
                                    }
                                })
                            }
                        </tr>
                        <tr>
                            {
                                headers.map(header => (
                                    header.span &&
                                    header.children.map((child, index) => (
                                        <th key={index} className={`${child.vertical}`}>{child.name.toUpperCase()}</th>
                                    ))
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            students.map((student, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    {
                                        headers.map((header, idx) => (
                                            header.span ?
                                                header.children.map((child, index) => (
                                                    <td key={idx} className={`text-${child.align || 'start'}`}>{student[child.key]}</td>
                                                ))
                                                :
                                                <td key={idx} className={`text-${header.align || 'start'}`}>{student[header.key]}</td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TopNStudents
