import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { saveAs } from 'file-saver'
import LevelSplit from '../../components/offices/LevelSplit'

function NewSchool() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [errorsFile, setErrorsFile] = useState('')

    const [wards, setWards] = useState([])

    const [name, setName] = useState('')
    const [reg_no, setRegNo] = useState('')
    const [school_reg_no, setSchoolRegNo] = useState('')
    const [location, setLocation] = useState('')
    const [ownership, setOwnership] = useState('')
    const [date_launched, setDateLaunched] = useState('')
    const [file, setFile] = useState('')

    const fetchWards = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/wards/none`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setWards(data.wards)
            }
        }
        catch (error) {
            setMessage("Failed to fetch wards. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    name,
                    reg_no,
                    school_reg_no,
                    location,
                    ownership,
                    date_launched
                })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage("Failed to register school. Please try again.")
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('Please wait while the file is being processed.')
        setVariant('info')
        setErrorsFile('')

        const formData = new FormData()
        formData.append('file', file)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/bulk`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
                if (response.status != 500) {
                    setErrorsFile(`${BASE_API_URL}/errors/file/${data.file.split('/').pop()}`)
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('Failed to upload file. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const downloadTemplate = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools/template`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }
            
            const filename = `mssis-template-schools-new.xlsx`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to download template. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchWards()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Register School</p>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className='shadow p-2 mb-4'>
                <form onSubmit={handleSubmit}>
                    <p className='h4 mb-3 text-muted'>School Info</p>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name' className='form-label'>Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    placeholder='eg. Kimali'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='reg_no' className='form-label'>NECTA Registration Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='reg_no'
                                    placeholder={auth.user.level === "PRIMARY"?"e.g PS12345":"e.g S3881"}
                                    required
                                    disabled={isLoading}
                                    value={reg_no}
                                    onChange={e => setRegNo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='school_reg_no' className='form-label'>School Registration Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='school_reg_no'
                                    placeholder={auth.user.level === "PRIMARY"?"e.g EM.12201":"e.g S.12201"}
                                    required
                                    disabled={isLoading}
                                    value={school_reg_no}
                                    onChange={e => setSchoolRegNo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='location' className='form-label'>Location (Ward)</label>
                                <select
                                    className='form-select rounded-0'
                                    id='location'
                                    required
                                    disabled={isLoading}
                                    value={location}
                                    onChange={e => setLocation(e.target.value)}
                                >
                                    <option value={""}>Select Ward</option>
                                    {
                                        wards?.map((ward, index) => (
                                            <option key={index} value={ward.name}>{ward.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='ownership' className='form-label'>Ownership</label>
                                <select
                                    className='form-select rounded-0'
                                    id='ownership'
                                    required
                                    disabled={isLoading}
                                    value={ownership}
                                    onChange={e => setOwnership(e.target.value)}
                                >
                                    <option value=''>Select ownership</option>
                                    <option value='GOVERNMENT'>Government</option>
                                    <option value='PRIVATE'>Private</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='date_launched' className='form-label'>Date Launched</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='date_launched'
                                    required
                                    disabled={isLoading}
                                    value={date_launched}
                                    onChange={e => setDateLaunched(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>

            <div className='shadow p-2 mb-4'>
                {
                    message &&
                    <>
                        <div className={`alert alert-${variant} p-2`} role="alert">
                            {message}
                        </div>
                        {
                            errorsFile &&
                            <div className="my-3">
                                <p className='text-danger'>
                                    The errors file appears here only once. If you do not download it now,
                                    you will not find it again!<br />
                                    Please download the file now and fix the errors, then re-upload it.
                                </p>
                                <a href={errorsFile} target='blank' download={true} className='btn btn-primary rounded-0'>
                                    <span className='me-2'>Download Errors File</span>
                                    <i className='bi bi-download'></i>
                                </a>
                            </div>
                        }
                    </>
                }

                <div className="d-flex align-items-center justify-content-between">
                    <p className='h4 text-muted'>Bulk Registration</p>
                    <button onClick={downloadTemplate} className='btn btn-primary rounded-0 mb-3'>
                        <span className='me-2'>Download Template</span>
                        <i className='bi bi-download'></i>
                    </button>
                </div>
                <p className='mb-3'>
                    You can register multiple schools at once by uploading an excel file.
                    <br />
                    First, download the template file, fill in the details and upload it here.
                </p>
                <div className='text-end mb-3'>
                    <form encType='multipart/form-data' onSubmit={handleUpload}>
                        <input
                            type='file'
                            className='form-control mb-3'
                            accept='.xlsx, .xls'
                            required
                            disabled={isLoading}
                            onChange={e => setFile(e.target.files[0])}
                        />
                        <button className='btn btn-primary rounded-0 px-4' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Uploading... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Upload
                                        </span>
                                        <i className='bi bi-upload'></i>
                                    </>
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewSchool
