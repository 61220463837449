import React from 'react'

function DivisionPerformancePrimary({ summary, nectaFormat }) {
    return (
        <div>
            {
                nectaFormat ?
                    <div className="table-responsive">
                        <table className='text-center necta-format'>
                            <caption>
                                PERFORMANCE SUMMARY
                            </caption>
                            <thead>
                                <tr>
                                    <th>SEX</th>
                                    {
                                        summary?.grades?.map((grade, index) => (
                                            <th key={index}>{grade.grade}</th>
                                        ))
                                    }
                                    <th>ABS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ["F", "M", "T"].map((gen, index) => (
                                        <tr key={index}>
                                            <td className='fw-bold'>{gen}</td>
                                            {
                                                summary?.grades?.map((grade, ind) => (
                                                    <td key={ind} className='px-3'>{summary["data"][gen][grade.grade]}</td>
                                                ))
                                            }
                                            <td>{summary["data"][gen]?.absent}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="table-responsive">
                        <table className="table table-bordered border-dark text-center table-warning table-sm">
                            <thead>
                                <tr>
                                    <th colSpan={2 + (summary?.grades?.length || 0)}>PERFORMANCE SUMMARY</th>
                                </tr>
                                <tr>
                                    <th>SEX</th>
                                    {
                                        summary?.grades?.map((grade, index) => (
                                            <th key={index}>{grade.grade}</th>
                                        ))
                                    }
                                    <th>ABSENT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ["F", "M", "T"].map((gen, index) => (
                                        <tr key={index}>
                                            <td className='fw-bold'>{gen}</td>
                                            {
                                                summary?.grades?.map((grade, ind) => (
                                                    <td key={ind} className='px-3'>{summary["data"][gen][grade.grade]}</td>
                                                ))
                                            }
                                            <td>{summary["data"][gen]?.absent}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default DivisionPerformancePrimary
