import React, { useEffect, useState } from 'react'
import RoleOffice from '../../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'

function SubjectTopicCoverage() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [filterSchool, setFilterSchool] = useState("")
    const [filterForm, setFilterForm] = useState("")
    const [filterSubject, setFilterSubject] = useState("")

    const [classes, setClasses] = useState([])
    const [filteredClasses, setFilteredClasses] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        ["DSEO"].includes(auth.user.role) &&
        {
            name: 'School Name',
            selector: row => row.school.name,
            sortable: true
        },
        {
            name: 'Class Name',
            selector: row => row.form.name,
            sortable: true
        },
        {
            name: 'Subject Name',
            selector: row => row.subject.name,
            sortable: true
        },
        {
            name: 'Progress',
            selector: row => row.progress,
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => <div>
                <Link
                    to={`view/${row.form.id}/${row.subject.id}`}
                    state={{ form: row.form, subject: row.subject, school: row.school }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            button: true
        }
    ]

    const filterData = () => {
        let filtered = classes

        if (filterSchool) {
            filtered = filtered.filter(item => item.school.id === filterSchool)
        }

        if (filterForm) {
            filtered = filtered.filter(item => item.form.id === filterForm)
        }

        if (filterSubject) {
            filtered = filtered.filter(item => item.subject.id === filterSubject)
        }

        setFilteredClasses(filtered)
    }

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/class-subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setClasses(data.classes)
                setFilteredClasses(data.classes)
            }
        }
        catch (error) {
            setMessage('Failed to fetch classes. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
    }, [])

    useEffect(() => {
        filterData()
    }, [filterSchool, filterForm, filterSubject])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Subject Topic Coverage</p>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} element={
                        <>
                            <Link to='view' className='btn btn-primary btn-sm fw-bold rounded-0 me-2'>
                                <span className="me-2">View Subject Topics</span>
                                <i className='bi bi-list-task'></i>
                            </Link>
                            <Link to='new' className='btn btn-primary btn-sm fw-bold rounded-0 me-2'>
                                <span className="me-2">Register</span>
                                <i className='bi bi-plus-square-fill'></i>
                            </Link>
                        </>
                    } />
                    
                    <RoleOffice roles={["Headmaster", "Academic Master", "Teacher"]} element={
                        <Link
                            to={'approve'}
                            className='btn btn-primary btn-sm rounded-0 fw-bold me-2'
                        >
                            <RoleOffice roles={["Headmaster", "Academic Master"]} element={
                                <span className="me-2">Sign and Approve</span>
                            } />
                            <RoleOffice roles={["Teacher"]} element={
                                <span className="me-2">Approval Status</span>
                            } />
                            <i className='bi bi-patch-check-fill'></i>
                        </Link>
                    } />

                    <Link to={"report"} className='btn btn-primary btn-sm rounded-0 fw-bold'>
                        <span className="me-2">View Report</span>
                        <i className='bi bi-file-earmark-text-fill'></i>
                    </Link>
                </div>
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    <>
                        {/* <div className="shadow p-2 mb-3">
                            <p>Coverage Summary and graphs</p>
                        </div> */}

                        <div className="shadow p-2 mb-3">
                            <p className="h5 text-muted">Select Class and Subject</p>

                            <div className="row mx-0 ">
                                <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} element={
                                    <div className="col-12 col-lg-3 mb-2">
                                        <label htmlFor='school' className='form-label'>School</label>
                                        <select
                                            id='school'
                                            className='form-select rounded-0'
                                            value={filterSchool}
                                            onChange={e => setFilterSchool(e.target.value)}
                                        >
                                            <option value=''>All schools</option>
                                            {
                                                Array.from(new Set(classes?.map(cl => cl.school.id)))
                                                    .map(id => {
                                                        const uniqueClass = classes.find(cl => cl.school.id === id)
                                                        return (
                                                            <option key={uniqueClass.school.id} value={uniqueClass.school.id}>
                                                                {uniqueClass.school.name}
                                                            </option>
                                                        )
                                                    })
                                            }
                                        </select>
                                    </div>
                                } />
                                <div className="col-12 col-lg-3 mb-2">
                                    <label htmlFor='class' className='form-label'>Class</label>
                                    <select
                                        id='class'
                                        className='form-select rounded-0'
                                        value={filterForm}
                                        onChange={e => setFilterForm(e.target.value)}
                                    >
                                        <option value=''>All Classes</option>
                                        {
                                            Array.from(new Set(classes?.map(cl => cl.form.id)))
                                                .map(id => {
                                                    const uniqueClass = classes.find(cl => cl.form.id === id)
                                                    return (
                                                        <option key={uniqueClass.form.id} value={uniqueClass.form.id}>
                                                            {uniqueClass.form.name}
                                                        </option>
                                                    )
                                                })
                                        }
                                    </select>
                                </div>
                                <div className="col-12 col-lg-3 mb-2">
                                    <label htmlFor='subject' className='form-label'>Subject</label>
                                    <select
                                        id='subject'
                                        className='form-select rounded-0'
                                        value={filterSubject}
                                        onChange={e => setFilterSubject(e.target.value)}
                                    >
                                        <option value=''>All Subjects</option>
                                        {
                                            Array.from(new Set(classes?.map(cl => cl.subject.id)))
                                                .map(id => {
                                                    const uniqueClass = classes.find(cl => cl.subject.id === id)
                                                    return (
                                                        <option key={uniqueClass.subject.id} value={uniqueClass.subject.id}>
                                                            {uniqueClass.subject.name}
                                                        </option>
                                                    )
                                                })
                                        }
                                    </select>
                                </div>
                            </div>

                            <DataTable
                                columns={columns}
                                data={filteredClasses}
                                highlightOnHover
                                pagination
                                paginationPerPage={perPage}
                                onChangePage={page => setCurrentPage(page)}
                                onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                paginationRowsPerPageOptions={[10, 20, 50, 150, 200]}
                                noDataComponent={"No classes found."}
                            />
                        </div>
                    </>
            }
        </div>
    )
}

export default SubjectTopicCoverage
