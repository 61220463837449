import React, { useEffect } from 'react'
import NavBar from '../components/navbar/NavBar'
import SideBar from '../components/sidebar/SideBar'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'

function Home() {
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    if (!auth.isLoggedIn()) {
        return <Navigate to='/login' state={{
            message: "You need to login to access the page",
            from: location.pathname
        }} />
    }
    else {
        return (
            <div className='home' id='body-pd'>
                <NavBar />
                <SideBar />
                <div className="">
                    <Outlet />
                </div>
            </div>
        )
    }
}

export default Home
