import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'

function NewReportDetails() {
    const auth = useAuth()
    const params = useParams()
    const examId = params.exam_id

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [currentSemester, setCurrentSemester] = useState("")
    const [openingSemester, setOpeningSemester] = useState("")
    const [openingDate, setOpeningDate] = useState("")
    const [openingTime, setOpeningTime] = useState("")
    const [teachersOpinion, setTeachersOpinion] = useState("")
    const [headmastersOpinion, setHeadmastersOpinion] = useState("")
    const [stamp, setStamp] = useState("")
    const [sign, setSign] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        const formData = new FormData()
        formData.append('currentSemester', currentSemester)
        formData.append('openingSemester', openingSemester)
        formData.append('openingDate', openingDate)
        formData.append('openingTime', openingTime)
        formData.append('teachersOpinion', teachersOpinion)
        formData.append('headmastersOpinion', headmastersOpinion)
        formData.append('stamp', stamp)
        formData.append('sign', sign)

        try {
            const response = await fetch(`${BASE_API_URL}/report/${examId}/details`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage("Failed to save report details. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>New Exam Report Details</p>
                    <p>Kiswahili is recommended when filling these details</p>
                </div>
            </div>

            <div className='shadow p-2 mb-4'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <form onSubmit={handleSubmit}>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='current_semester' className='form-label'>
                                    Current Semester <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='current_semester'
                                    required
                                    placeholder='e.g Kwanza'
                                    value={currentSemester}
                                    onChange={e => setCurrentSemester(e.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='opening_semester' className='form-label'>
                                    Opening Semester <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='opening_semester'
                                    required
                                    placeholder='e.g Pili'
                                    value={openingSemester}
                                    onChange={e => setOpeningSemester(e.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='opening_date' className='form-label'>
                                    Opening Date <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='opening_date'
                                    required
                                    value={openingDate}
                                    onChange={e => setOpeningDate(e.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='opening_time' className='form-label'>
                                    Opening Time <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='time'
                                    className='form-control rounded-0'
                                    id='opening_time'
                                    required
                                    value={openingTime}
                                    onChange={e => setOpeningTime(e.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="teachers_opinion" className="form-label">Class Teacher's Opinion</label>
                                <textarea
                                    className='form-control'
                                    id='teachers_opinion'
                                    value={teachersOpinion}
                                    onChange={e => setTeachersOpinion(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="headmasters_opinion" className="form-label">Headmaster's Opinion</label>
                                <textarea
                                    className='form-control'
                                    id='headmasters_opinion'
                                    value={headmastersOpinion}
                                    onChange={e => setHeadmastersOpinion(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <label htmlFor="stamp" className="form-label">
                                Stamp <span>
                                    (If you already uploaded your stamp 
                                    <Link to={'/administration/logo-stamps'}> here </Link> 
                                    you can skip this)
                                </span>
                            </label>
                            <input
                                type="file"
                                id="stamp"
                                accept="image/*"
                                className="form-control"
                                onChange={e => setStamp(e.target.files[0])}
                            />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <label htmlFor="sign" className="form-label">
                                Signature <strong className='text-danger ms-1'>* </strong>
                                {
                                    auth.user.signature &&
                                    <span>(We already have your signature)</span>
                                }
                            </label>
                            <input
                                type="file"
                                id="sign"
                                accept="image/*"
                                className="form-control"
                                required
                                disabled={auth.user.signature}
                                onChange={e => setSign(e.target.files[0])}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <span>
                            <strong className='text-danger ms-1'>*</strong> indicates required fields
                        </span>
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Saving... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Save
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewReportDetails
