import { ArcElement, Chart, Legend, Tooltip } from 'chart.js'
import React from 'react'
import { Pie } from 'react-chartjs-2'

function TeachingProgressPie({ values }) {
    Chart.register(ArcElement, Tooltip, Legend)
    const data = {
        labels: ["Taught", "In Progress", "Not Taught"],
        datasets: [
            {
                label: "Count",
                data: values,
                backgroundColor: ["#0d6efd", "#ffc107", "#d63384"],
                borderColor: ["rgba(255,99,132,1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
                borderWidth: 1,
            },
        ]
    }

    const options = {
        plugins: {
            legend: {
                position: 'right',
            },
        },
    }

    return (
        <Pie data={data} options={options} />
    )
}

export default TeachingProgressPie
