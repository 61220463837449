import React, { useEffect, useState } from 'react'
import ResultsTitle from '../../components/results/ResultsTitle'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'

function CreateResultsTitle() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [mainAuthority, setMainAuthority] = useState("")
    const [subAuthority, setSubAuthority] = useState("")
    const [region, setRegion] = useState("")
    const [district, setDistrict] = useState("")
    const [language, setLanguage] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        if (mainAuthority.length < 3 || subAuthority.length < 3 || region.length < 3 || district.length < 3) {
            setMessage('All fields are required. At least 3 characters each.')
            setVariant('warning')
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/results/title`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ mainAuthority, subAuthority, region, district, language })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage('An error occurred. Please try again')
            setVariant('danger')
        }

        setIsLoading(false)
    }

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>Create Exam Results Title</p>
                </div>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                <div className="mb-3">
                    <p className='text-muted fs-4'>How the values will appear</p>
                    <ResultsTitle
                        exam={{ start_date: '05/07/2024', name: 'SAMPLE EXAM' }}
                        form={auth.user.level === "PRIMARY" ? { name: 'CLASS I' } : { name: 'FORM 1' }}
                        school={{ reg_no: auth.user.level === "PRIMARY" ? 'PS12345' : 'S1234', name: 'EXAMPLE' }}
                        main_authority={mainAuthority}
                        sub_authoruty={subAuthority}
                        region={region}
                        district={district}
                        lang={language}
                        display={true}
                    />
                </div>

                <div className="mb-3">
                    <p className='text-muted fs-4'>Update values</p>
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='main_authority' className='form-label'>Main Authority</label>
                                    <input
                                        type='text'
                                        className='form-control rounded-0'
                                        id='main_authority'
                                        placeholder="e.g PRESIDENT'S OFFICE"
                                        required
                                        disabled={isLoading}
                                        value={mainAuthority}
                                        onChange={e => setMainAuthority(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='sub_authority' className='form-label'>Sub Authority</label>
                                    <input
                                        type='text'
                                        className='form-control rounded-0'
                                        id='sub_authority'
                                        placeholder="e.g REGIONAL ADMINISTRATION AND LOCAL GOVERNMENT"
                                        required
                                        disabled={isLoading}
                                        value={subAuthority}
                                        onChange={e => setSubAuthority(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='region' className='form-label'>Region</label>
                                    <input
                                        type='text'
                                        className='form-control rounded-0'
                                        id='region'
                                        placeholder="e.g SIMIYU REGION"
                                        required
                                        disabled={isLoading}
                                        value={region}
                                        onChange={e => setRegion(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='district' className='form-label'>District</label>
                                    <input
                                        type='text'
                                        className='form-control rounded-0'
                                        id='district'
                                        placeholder='e.g MEATU DISTRICT COUNCIL'
                                        required
                                        disabled={isLoading}
                                        value={district}
                                        onChange={e => setDistrict(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='language' className='form-label'>Language</label>
                                    <select
                                        id='language'
                                        className='form-select rounded-0'
                                        required
                                        disabled={isLoading}
                                        value={language}
                                        onChange={e => setLanguage(e.target.value)}
                                    >
                                        <option value="">Choose Language Used</option>
                                        <option value="ENGLISH">English</option>
                                        <option value="KISWAHILI">Kiswahili</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {
                            message &&
                            <div className={`alert alert-${variant} p-2`} role="alert">
                                {message}
                            </div>
                        }

                        <div className="text-end mb-3">
                            <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                                {
                                    isLoading ?
                                        <>Creating... <Loading /></> :
                                        <>
                                            <span className="me-2">
                                                Create
                                            </span>
                                            <i className='bi bi-floppy2-fill'></i>
                                        </>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateResultsTitle
