import React from 'react'
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import RoleOffice from '../../components/offices/RoleOffice'
import GeneralSettings from './tabs/GeneralSettings'
import SMSSettings from './tabs/SMSSettings'

function Settings() {
	return (
		<div className='pt-2'>
			<p className='h3 fw-bold text-muted'>Settings</p>
			<div className="shadow p-2">
				<Tabs>
					<TabList>
						<Tab>General</Tab>
						<Tab>SMS</Tab>
					</TabList>

					<TabPanel>
						<GeneralSettings />
					</TabPanel>
					<TabPanel>
						<SMSSettings />
					</TabPanel>
				</Tabs>
			</div>
		</div>
	)
}

export default Settings
