import React, { useEffect, useState } from 'react'
import Card from '../../../components/cards/Card'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../../utils/constants'
import { useAuth } from '../../../context/AuthContext'

function SMSSettings() {
	const auth = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [message, setMessage] = useState("")
	const [variant, setVariant] = useState("success")

	const purchaseColumns = [
		{
			name: "S/N",
			selector: (row, index) => index + 1,
			sortable: true,
			grow: 0
		},
		{
			name: "Staff Name",
			selector: row => row.staff,
			sortable: true
		},
		{
			name: "SMS Quantity",
			selector: row => row.sms_count,
			sortable: true
		},
		{
			name: "Unit Cost",
			selector: row => row.unit_cost,
			sortable: true
		},
		{
			name: "Total Cost",
			selector: row => row.cost,
			sortable: true
		},
		{
			name: "Purchase Date",
			selector: row => row.date_purchased,
			sortable: true
		},
		{
			name: "Status",
			selector: row => row.status,
			sortable: true
		}
	]

	const usageColumns = [
		{
			name: "S/N",
			selector: (row, index) => index + 1,
			sortable: true,
			grow: 0
		},
		{
			name: "SMS Used",
			selector: row => row.sms_spent,
			sortable: true
		},
		{
			name: "SMS Receivers",
			selector: row => row.receivers_count,
			sortable: true
		},
		{
			name: "Date Sent",
			selector: row => row.date_sent,
			sortable: true
		},
		{
			name: "Description",
			selector: row => row.description,
			sortable: true,
			grow: 4
		},
		{
			name: "Staff Name",
			selector: row => row.staff,
			sortable: true
		}
	]

	const [cards, setCards] = useState([])
	const [priceRanges, setPriceRanges] = useState([])
	const [purchaseHistory, setPurchaseHistory] = useState([])
	const [usageHistory, setUsageHistory] = useState([])
	const [quantity, setQuantity] = useState("")
	const [unitCost, setUnitCost] = useState(0.0)
	const [totalCost, setTotalCost] = useState(0.0)

	const fetchSmsDetails = async () => {
		setIsLoading(true)

		try {
			const response = await fetch(`${BASE_API_URL}/sms/details`, {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant("warning")
			}
			else {
				setPriceRanges(data.prices)
				setPurchaseHistory(data.purchase_history)
				setUsageHistory(data.usage_history)
				setCards(data.cards)
			}
		}
		catch (error) {
			setMessage("Failed to fetch SMS details. Please try again.")
			setVariant("danger")
		}
		finally {
			setIsLoading(false)
		}
	}

	const handlePurchase = async (e) => {
		e.preventDefault()

		if (isNaN(quantity)) {
			setMessage("Enter a valid SMS quantity.")
			setVariant("danger")
			return
		}
		else if (Number(quantity) < 1000) {
			setMessage("Minimum allowed SMS quantity is 1000.")
			setVariant("danger")
			return
		}

		setIsLoading(true)
		setMessage("")

		try {
			const response = await fetch(`${BASE_API_URL}/sms/purchase`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": auth.token
				},
				body: JSON.stringify({
					quantity
				})
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant("warning")
			}
			else {
				setMessage(data.message)
				setVariant("success")
				fetchSmsDetails()
			}
		}
		catch (error) {
			setMessage("Failed to purchase SMS. Please try again.")
			setVariant("danger")
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchSmsDetails()
	}, [])

	return (
		<div className='py-2'>
			<div className='row mx-0 mb-3'>
				{
					cards.map((card, index) => (
						<Card key={index} card={card} />
					))
				}
			</div>
			{
				message &&
				<div className={`alert alert-${variant} p-2`} role="alert">
					{message}
				</div>
			}
			<div className="mb-3">
				<p className="h5 fw-bold text-muted">Buy SMS</p>
				<div className="table-responsive mb-3">
					<table className="table nowrap">
						<tbody>
							<tr>
								<td><strong>Message</strong></td>
								{
									priceRanges.map((pr, index) => (
										<td className="text-center">{pr.min_amount} - {pr.max_amount}</td>
									))
								}
							</tr>
							<tr>
								<td><strong>Price (Tsh)</strong></td>
								{
									priceRanges.map((pr, index) => (
										<td className="text-center">{pr.price}</td>
									))
								}
							</tr>
						</tbody>
					</table>
				</div>

				<form onSubmit={handlePurchase}>
					<div className="mb-3 form-group">
						<label htmlFor="amount" className="form-label">
							SMS Quantity (Minimum quantity: 1000)
						</label>
						<input
							type="number"
							className="form-control rounded-0"
							placeholder='0'
							pattern='[1-9]+'
							value={quantity}
							onChange={(e) => {
								let value = e.target.value
								if (isNaN(value) || !value) {
									setTotalCost(0)
								}
								else {
									value = Number(value)
									value = Math.round(value)

									let price = priceRanges.find(val => value >= Number(val.min_amount) && (value <= Number(val.max_amount) || !val.max_amount))
									if (price) {
										setUnitCost(Number(price.price))
										setTotalCost(value * Number(price.price))
									}
								}
								setQuantity(value)
							}}
						/>
					</div>
					<div className="mb-3 form-group">
						<label htmlFor="cost" className="form-label">
							Unit Cost (Changes depending on the amount of SMS bought)
						</label>
						<input type="number" className="form-control rounded-0" disabled value={unitCost} />
					</div>
					<div className="mb-3 form-group">
						<label htmlFor="total" className="form-label">Total Cost (Tsh)</label>
						<input type="number" className="form-control rounded-0" disabled value={totalCost} />
					</div>
					<div className="mb-3 text-end">
						<button type="submit" className="btn btn-primary rounded-0 fw-bold px-4" disabled={isLoading || !quantity}>
							<span className="me-2">Buy</span>
							<i className="bi bi-cash-stack"></i>
						</button>
					</div>
				</form>
			</div>

			<div className="mb-3">
				<p className="h5 fw-bold text-muted">SMS Purchase History</p>
				<small className="mb-2">Shows the last 10 SMS purchases made</small>
				<DataTable
					columns={purchaseColumns}
					data={purchaseHistory}
					noDataComponent={"No SMS purchased yet."}
				/>
			</div>

			<div className="mb-3">
				<p className="h5 fw-bold text-muted">SMS Usage History</p>
				<small className="mb-2">Shows the last 10 SMS sent</small>
				<DataTable
					columns={usageColumns}
					data={usageHistory}
					noDataComponent={"No SMS usage history."}
				/>
			</div>
		</div>
	)
}

export default SMSSettings
