import React from 'react'

function DivisionPerformance({ summary, nectaFormat }) {
    return (
        <div>
            {
                nectaFormat ?
                    <div className="table-responsive">
                        <table className='text-center necta-format'>
                            <caption>
                                DIVISION PERFORMANCE SUMMARY
                            </caption>
                            <thead>
                                <tr>
                                    <th>SEX</th>
                                    <th>I</th>
                                    <th>II</th>
                                    <th>III</th>
                                    <th>IV</th>
                                    <th>0</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(summary).map((row, index) => (
                                        <tr key={index}>
                                            <td className='fw-bold'>{row}</td>
                                            <td>{summary[row]["I"]}</td>
                                            <td>{summary[row]["II"]}</td>
                                            <td>{summary[row]["III"]}</td>
                                            <td>{summary[row]["IV"]}</td>
                                            <td>{summary[row]["0"]}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="table-responsive">
                        <table className="table table-bordered border-dark text-center table-warning table-sm">
                            <thead>
                                <tr>
                                    <th colSpan={9}>DIVISION PERFORMANCE SUMMARY</th>
                                </tr>
                                <tr>
                                    <th>SEX</th>
                                    <th>I</th>
                                    <th>II</th>
                                    <th>III</th>
                                    <th>IV</th>
                                    <th>0</th>
                                    <th>INCOMPLETE</th>
                                    <th>ABSENT</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(summary).map((row, index) => (
                                        <tr key={index}>
                                            <td className='fw-bold'>{row}</td>
                                            <td className='px-3'>{summary[row]["I"]}</td>
                                            <td className='px-3'>{summary[row]["II"]}</td>
                                            <td className='px-3'>{summary[row]["III"]}</td>
                                            <td className='px-3'>{summary[row]["IV"]}</td>
                                            <td className='px-3'>{summary[row]["0"]}</td>
                                            <td>{summary[row]["incomplete"]}</td>
                                            <td>{summary[row]["absent"]}</td>
                                            <td>{summary[row]["total"]}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default DivisionPerformance
