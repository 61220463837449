import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import RoleOffice from '../../components/offices/RoleOffice'
import { BASE_API_URL } from '../../utils/constants'

function Exams() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [filteredExams, setFilteredExams] = useState([])
    const [exams, setExams] = useState([])

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.schools,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.start_date,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.end_date,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                <Link
                    to={`/academics/exams/${row.id}`}
                    state={{ exam: row }}
                    className='btn btn-sm btn-primary rounded-0 me-2'
                >
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
                {
                    ["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"].includes(auth.user.role) &&
                    <Link
                        to={`/teaching/results/${row.id}`}
                        state={{ exam: row }}
                        className={`btn btn-sm btn-primary rounded-0 me-2 ${row.type === 'Internal' ? 'disabled' : ''}`}
                    >
                        <i className='bi bi-upload fs-6 mx-1'></i>
                    </Link>
                }
                <Link
                    to={`/academics/results/${row.id}/${row.type.toLowerCase()}`}
                    state={{ exam: row }}
                    className='btn btn-sm btn-primary rounded-0'
                >
                    <i className='bi bi-graph-up-arrow fs-6 mx-1'></i>
                </Link>
            </div>,
            sortable: false,
        }
    ]

    const handleSearch = (value) => {
        let data = exams.filter(exam => (
            exam.name.toLowerCase().includes(value.toLowerCase()) ||
            exam.start_date.includes(value.toLowerCase()) ||
            exam.end_date.includes(value.toLowerCase())
        ))
        setFilteredExams(data)
    }

    const fetchExams = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setFilteredExams(data.exams)
                setExams(data.exams)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exams. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExams()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Exams</p>
                <RoleOffice
                    roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Academic Master"]}
                    element={
                        <Link to='/academics/exams/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                            <span className="me-2">Register</span>
                            <i className='bi bi-plus-square-fill'></i>
                        </Link>
                    }
                />
            </div>
            <div className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder="Filter exams..."
                    onChange={e => handleSearch(e.target.value)}
                />
                <button className="btn btn-sm btn-primary px-3 fw-bold rounded-0 d-flex align-items-center">
                    <span className="me-2">Filter</span>
                    <i className='bi bi-funnel-fill'></i>
                </button>
            </div>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={filteredExams}
                            highlightOnHover
                            pagination
                            paginationPerPage={perPage}
                            onChangePage={page => setCurrentPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            paginationRowsPerPageOptions={[10, 20, 50, 150, 200]}
                            noDataComponent={"No exams published or done yet."}
                        />
                }
            </div>
        </div>
    )
}

export default Exams
