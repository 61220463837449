import React from 'react'

function NotFound() {
  return (
    <div className="text-center text-muted h3 pt-3">
        Page Not Found <i className="bi bi-exclamation-triangle-fill text-warning ms-2"></i>
    </div>
  )
}

export default NotFound
