import React from 'react'
import { Link } from 'react-router-dom'

function TeachingLearningMaterials() {
    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Teaching and Learning Materials</p>
            <div className="body shadow p-2 mb-3">
                <Link to={"subject-topic-coverage"} className="btn btn-primary w-100 mb-3 text-white fw-bold rounded-0">
                    Subject Topics Coverage
                </Link>
                <Link to={"teachers-logbook"} className="btn btn-primary w-100 mb-3 text-white fw-bold rounded-0">
                    Teacher's Subject Log book
                </Link>
                <Link to={""} className="btn btn-primary w-100 mb-3 disabled text-white fw-bold rounded-0">
                    Teaching Timetable
                </Link>
                <Link to={""} className="btn btn-primary w-100 mb-3 disabled text-white fw-bold rounded-0">
                    Subject Lesson Plans
                </Link>
                <Link to={""} className="btn btn-primary w-100 mb-3 disabled text-white fw-bold rounded-0">
                    Schemes of Work
                </Link>
                <Link to={""} className="btn btn-primary w-100 mb-3 disabled text-white fw-bold rounded-0">
                    Subject Lesson Notes
                </Link>
                <Link to={""} className="btn btn-primary w-100 mb-3 disabled text-white fw-bold rounded-0">
                    Exam Papers/Past Papers
                </Link>
                <Link to={""} className="btn btn-primary w-100 mb-3 disabled text-white fw-bold rounded-0">
                    TIE Subject Books
                </Link>
                <Link to={""} className="btn btn-primary w-100 mb-3 disabled text-white fw-bold rounded-0">
                    Subject Syllabuses
                </Link>
            </div>
        </div>
    )
}

export default TeachingLearningMaterials
