import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import SingleSubjectTopicCoverage from '../../../components/topic-coverage/SingleSubjectTopicCoverage'
import { saveAs } from 'file-saver'
import LevelSplit from '../../../components/offices/LevelSplit'

function SubjectLogBook() {
    const auth = useAuth()
    const params = useParams()
    const formId = params.formId
    const subjectId = params.subjectId
    const location = useLocation()
    const schoolId = location?.state?.schoolId

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [school, setSchool] = useState({})
    const [details, setDetails] = useState([])

    const fetchLogBook = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/logbook/${formId}/${subjectId}?school=${schoolId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setDetails(data.details)
                setSchool(data.details.school)
            }
        }
        catch (error) {
            setMessage("Failed to fetch log book. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const exportPDF = async () => {
        setIsLoading(true)
        setMessage("")

        try {
            let url = `${BASE_API_URL}/logbook/export/${formId}/${subjectId}?school=${schoolId}`
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant("warning")
                return
            }
            
            const form = details?.form?.name
            const subject = details?.subject?.name
			const filename = `${form} ${subject} LogBook.pdf`.toUpperCase()
			const blob = await response.blob()
			saveAs(blob, filename)
        }
        catch(error) {
            setMessage("Failed to export logbook to PDF. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchLogBook()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <p className='h3 fw-bold text-muted'>Teacher's Subject Log book</p>
                <button
                    className='btn btn-sm btn-primary rounded-0 fw-bold'
                    disabled={isLoading}
                    onClick={exportPDF}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>
            <p className='h5 text-muted'>
                School Name: <strong>
                    {school?.name?.toUpperCase()} <LevelSplit primary={"PRIMARY"} ordinary={"SECONDARY"} /> SCHOOL
                    </strong>
                <br />
                Class Name: <strong>{details?.form?.name}</strong>
                <br />
                Subject Name: <strong>{details?.subject?.name}</strong>
                <br />
                Teacher's Name: <strong>{details?.teacher?.firstname} {details?.teacher?.lastname}</strong>
                <br />
                Teacher's Phone: <strong>{details?.teacher?.phone}</strong>
            </p>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="shadow p-2 mb-3">
                {
                    isLoading && <div className="text-center">Loading... <Loading /></div>
                }
                {
                    !isLoading &&
                    <SingleSubjectTopicCoverage coverage={details?.coverage} isLogBook={true} />
                }
            </div>
        </div>
    )
}

export default SubjectLogBook
