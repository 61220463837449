import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { useLocation } from 'react-router-dom'

function NewLocation() {
    const auth = useAuth()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [locations, setLocations] = useState(location.state?.locations || [])
    const [region, setRegion] = useState('')
    const [district, setDistrict] = useState('')
    const [ward, setWard] = useState('')

    const fetchLocations = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/locations`, {
                headers: {
                    'x-access-token': auth.token
                }
            }
            )
            const data = await response.json()

            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setLocations(data.locations)
            }
        }
        catch (error) {
            setMessage("Failed to fetch locations. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/locations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    region,
                    district,
                    ward
                })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage("Failed to register location. Please try again.")
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!locations || locations.length < 1) {
            fetchLocations()
        }
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Register School</p>
            <div className='shadow p-2'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                {
                    isLoading ?
                        <div className='text-center'>
                            <Loading />
                        </div>
                        :
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="region" className="form-label">Region: </label>
                                <input
                                    list="region-options"
                                    id="region"
                                    name="region"
                                    className="form-control rounded-0"
                                    value={region}
                                    onChange={e => setRegion(e.target.value)}
                                />
                                <datalist id="region-options">
                                    {
                                        locations.map((location, index) => (
                                            <option key={index} value={location.name} />
                                        ))
                                    }
                                </datalist>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="district" className="form-label">District: </label>
                                <input
                                    list="district-options"
                                    id="district"
                                    name="district"
                                    className="form-control rounded-0"
                                    value={district}
                                    onChange={e => setDistrict(e.target.value)}
                                />
                                <datalist id="district-options">
                                    {
                                        locations.find(location => location.name === region)?.districts.map((district, index) => (
                                            <option key={index} value={district.name} />
                                        ))
                                    }
                                </datalist>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="ward" className="form-label">Ward:</label>
                                <input
                                    list="ward-options"
                                    id="ward"
                                    name="ward"
                                    className="form-control rounded-0"
                                    value={ward}
                                    onChange={e => setWard(e.target.value)}
                                />
                                <datalist id="ward-options">
                                    {
                                        locations.find(location => location.name === region)?.districts.find(district => district.name === district)?.wards.map((ward, index) => (
                                            <option key={index} value={ward.name} />
                                        ))
                                    }
                                </datalist>
                            </div>
                            <div className="mb-3 text-end">
                                <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                                    {
                                        isLoading ?
                                            <>Registering... <Loading /></> :
                                            <>
                                                <span className="me-2">
                                                    Register
                                                </span>
                                                <i className='bi bi-floppy2-fill'></i>
                                            </>
                                    }
                                </button>
                            </div>
                        </form>
                }
            </div>
        </div>
    )
}

export default NewLocation
