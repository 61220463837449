import React from 'react'
import { useAuth } from '../../context/AuthContext'
import { Navigate } from 'react-router-dom'

function RoleRedirect({roles, children, to="/"}) {
    const auth = useAuth()
    const user = auth.user

    // replace headmistress with headmaster and academic mistress with academic master
    let user_role = user.school?user.school.role:user.role
    if (user_role === 'Headmistress') user_role = 'Headmaster'
    if (user_role === 'Academic Mistress') user_role = 'Academic Master'

    const role_test = roles.includes(user_role)
    const school_role_test = user.school?roles.includes(user_role):false
    if (user && !(role_test || school_role_test)) {
        return <Navigate to={to} state={{message: 'You are not authorized to access this page.'}} />
    }

    return (<>{children}</>)
}

export default RoleRedirect
