import React from 'react'
import { Link } from 'react-router-dom'

function Card({card}) {
	return (
		<Link to={card?.link} className="col-lg-3 col-12 mb-3 pe-2 text-dark">
			<div className={`bg-${card.color} shadow ps-2`}>
				<div className="bg-white p-2">
					<span className="h3 fw-bold">
						{card?.value}
					</span>
					<div className="d-flex align-items-center justify-content-between">
						<span className="text-muted fs-5">{card?.title}</span>
						<i className={`bi bi-${card?.icon} text-${card?.color} fs-2`}></i>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default Card